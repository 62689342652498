@import '../../utils/colors.scss';
@import '../../utils/sizes.scss';
.modal-product {
    // max-width: 1400px;
    max-width: $maxContentWidth;
    width: 80%;
    margin: 0 auto;
    background-color: $backgroundGray;
    padding: 2.5rem;
    flex-direction: column;

    align-items: center;
    display: flex;
    height: auto;
    max-height: 85%;


    &__back {
        padding: 1rem;
        color: $backgroundGreen;
        font-size: 1.1rem;
        align-self: flex-start;
    }
    &__info {
        // width: 100%;
        // height: 50%;

        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
        margin-bottom: 2rem;
        &--badges {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;

            &-badge {
                height: 1.5rem;
                display: flex;
                flex-direction: row;
                gap: .5rem;

                &-img {
                    border-radius: 50%;
                }
            }
        }

        &-img {
            position: relative;
            width: 100%;
            img {
                // width: 100%;
                // height: auto;
                height: auto;
                max-height: inherit;
                max-width: 100%;
                width: auto;
            }
        
            &--items {
                display: none;
            }
            &.--has-items {
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                img {
                    filter: saturate(5%) blur(2px);
                }
                .modal-product__info-img--items {
                    width: 100%;
                    // height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    // display: flex;
                    // flex-direction: column;
                    z-index: 2;

                    .image-wrapper-item {
                        width: 100%;
                        padding: .8rem;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        
                        
                        &__name, small {
                            color: $backgroundGreen;
                            font-size: .7rem;
                            font-weight: 500;
                        }

                        &__counter {
                            // width: 70px;
                            height: 30px;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-around;
                            align-items: center;
                            gap: 1rem;
                            color: $backgroundGreen;

                            &--btn-counter {
                                width: 20px;
                                height: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                                color: $backgroundGreen;
                                border: 2px solid $backgroundGreen;
                                background-color: rgba(0, 0, 0, 0);
                                font-size: .6rem;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
        &-content {
            display: flex;
            flex-direction: column;
            height: 100%;
            &--header {
                display: flex;
                flex: 0 0 auto;
                flex-direction: column;
                h1 {
                    align-self: flex-start;
                    font-size: 1.8em;
                    justify-self: flex-start;
                    text-align: left;
                    color: $backgroundGreen;
                }
                .header-description {
                    color: #1c1c1c;
                    font-size: .9375rem;
                    margin-top: 1rem;
                    text-align: justify;
                }
            }
            .product-modifiers {
                display: flex;
                flex-direction: column;
                margin-bottom: 1rem;
                h2 {
                    font-size: 1rem;
                    color: $backgroundGreen;
                }
                &__options {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 0.7rem;

                    &-option {
                        border: 1px solid $backgroundGreen;
                        border-radius: 10px;
                        color: $backgroundGreen;
                        padding: 2px 10px;

                        &.--choosen {
                            background-color: $backgroundGreen;
                            color: $backgroundBlack;
                        }
                    }
                }
            }
            &--nutrition {
                display: flex;
                flex-direction: row;
                flex: 1 1 auto;
                justify-content: space-between;
                // align-items: flex-end;
                gap: 1rem;
                .energy-value, .nutrition {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    height: 100%;
                    margin-top: 1.5rem;
                    &>* {
                        font-size: .85rem;
                    }
                    font-size: .9rem;
                }
            }
            &--footer {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0;
                align-items: flex-end;
                flex: 0 0 auto;
                margin-top: 1.5rem;

                .weight {
                    display: flex;
                    flex-direction: row;
                    font-size: 1.5rem;
                    color: $backgroundGreen;
                    align-items: center;
                    .price {
                        font-size: 1.75rem;
                    }
                }
                .add-to-cart {
                    border: 0;
                    background-color: rgba(255, 255, 255, 0);
                    border: 3px solid rgba($orange, .8);
                    color: $orange;
                    font-size: 1rem;
                    outline: none;
                    padding: 0.2rem 1rem;
                    border-radius: 1rem;
                    align-self: center;
                }
            }
        }
    }
}


@media (max-width: $mobileWidth) {
    .modal-product {
        width: 100%;
        min-width: 0;
        padding: 0 1rem;
        &__info {
            display: flex;
            flex-direction: column;
        }
    }
}