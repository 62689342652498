@import '../../utils/colors.scss';

.categories-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .5rem;
    grid-area: categories;
    &__item {
        cursor: pointer;
        font-size: 1.1rem;
        // margin: 0 1vw;
        padding: 5px 20px;
        color: $backgroundGreen;
        border-radius: 10px;
        &:hover {
            background-color: rgba($backgroundGreen, .5);
            color: $backgroundBlack;
        }
        &.active {
            background-color: $backgroundGreen;
            color: $backgroundBlack;
        }
    }
}

.subcategories-list {
    grid-area: subcat;
    align-self:flex-start;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    position: sticky;
    top: 2rem;
    &__item {
        cursor: pointer;
        font-size: 100%;
        // margin: 0 1vw;
        padding: 5px 20px;
        border-radius: 10px;
        color: $backgroundGreen;
        &:hover {
            background-color: rgba($backgroundGreen, .5);
            color: $backgroundBlack;
        }
        &.active {
            background-color: $backgroundGreen;
            color: $backgroundBlack;
        }
    }
}