@import "../../utils/colors.scss";
@import "../../utils/sizes.scss";
.footer {
    width: 100%;
    color: white;
    background-color: $backgroundGreen;

    &__inner {
        width: 100%;
        margin: 0 auto;
        max-width: 1200px;
        padding: 2rem;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        padding-bottom: 0;
        padding-top: 5rem;
        overflow: hidden;
    }
    &__phone {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        // margin-top: 2rem;
        a {
            font-weight: bold;
        }
    }
    &__docs {
        display: flex;
        flex-direction: row;
        color: #929292;
        width: 100%;
        margin: 0 auto;
        max-width: 1200px;
        padding: 0 2rem 1rem;
        justify-content: space-between;
        gap: 1rem;
        font-size: .8rem;
    }
    &__mobile {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        min-height: 12rem;
       
        &-phone {
            width: 10rem;
            position: relative;
          
            &>img {
                width: 10rem;
                height: auto;
                
                position: absolute;
                top: 0;
                left: 0;
                transition: all .5s;
                &:hover {
                    transform: translateY(-1.5rem);
                }
            }
        }
       
        &-text {
            display: flex;
            flex-direction: column;
            justify-content: end;
            text-transform: uppercase;
            gap: 1rem;
            span {
                display: flex;
                flex-direction: column;
                span {
                    font-weight: bold;
                }
            }
            &--links {
                width: 100%;
                display: flex;
                flex-direction: row;
                gap: .5rem;

                img {
                    height: 2.2rem;
                    width: auto;
                    border-radius: 0;
                }
            }
        }
    }
    &.--minicart {
        padding-bottom: 80px;
    }
    &__sub {
        z-index: 2;
        position: relative;
        background-color: $backgroundGray;
    }
    &__socials {
        width: 100%;
        margin: 0 auto;
        max-width: 1200px;
        padding: 1rem 2rem;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: space-between;
        &-links {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;
        }
        img {
            width: 2.2rem;
            height: auto;
        }
    }
    &__work-on {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $backgroundGreen;
        gap: .5rem;
        a {
            display: flex;
            align-items: center;
        }
    }
   
  
}

@media (max-width: $mobileWidth) {
    .footer {
        
        &__mobile {
            display: none;
        }
        &__inner {
            padding: 1rem;
        }
        &__phone {
            justify-content: center;
            text-align: center;
            margin-top: 0;
        }
        &.--minicart {
            padding-bottom: 60px;
        }
        &__socials {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        &__docs {
            flex-direction: column;
            text-align: center;
        }
    }

}