.placeholder {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000000;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1rem;
    img {
        width: 100%;
        max-width: 600px;
        aspect-ratio: 1;
        min-width: 50px;
        object-fit: cover;
    }
}